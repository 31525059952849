import React from 'react';
import { Form, Input, Button, Image } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

interface SignInProps {
  onSignIn: (userData: any) => void;
}

const SignIn: React.FC<SignInProps> = ({ onSignIn }) => {
    const navigate = useNavigate();

    const onFinish = (values: any) => {
        console.log('Received values of form: ', values);
        // Call sign-in API here

        // Mock user data to save as token
        const userData = {
          email: values.email,
          name: 'John Doe', // Mocked name
          organization: 'UltraAI Clinic', // Mocked organization
        };

        onSignIn(userData);

        // Redirect to default page
        navigate('/');
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ width: '100%', maxWidth: 400, textAlign: 'center' }}>
                {/* <Title level={2}>UltraAI Hub</Title> */}
                <Image
                  className="w-full h-full py-[30px] cursor-pointer"
                  alt="logo"
                  preview={false}
                  src={'/UltraAI_logo.png'}
                  height={40}
                  style={{
                    objectFit: 'contain',
                  }}
                />
                <Form
                    name="sign_in"
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark={false}
                    style={{ marginTop: '20px' }}
                >
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                    >
                        <Input size="large" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password size="large" />
                    </Form.Item>

                    <Form.Item>
                        <Button size="large" type="primary" htmlType="submit" block>
                            Sign In
                        </Button>
                    </Form.Item>

                    <div style={{ marginTop: '10px' }}>
                        Don't have an account? <Link to="/signup">Sign Up</Link>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default SignIn;
