import React from 'react';
import { Form, Input, Button, Select, Image } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

const { Option } = Select;

const SignUp: React.FC = () => {
    const navigate = useNavigate();

    const onFinish = (values: any) => {
        console.log('Received values of form: ', values);
        // Call sign-up API here
        navigate('/signin');
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ width: '100%', maxWidth: 400, textAlign: 'center' }}>
                {/* <Title level={2}>UltraAI Hub</Title> */}
                <Image
                  className="w-full h-full py-[30px] cursor-pointer"
                  alt="logo"
                  preview={false}
                  src={'/UltraAI_logo.png'}
                  height={40}
                  style={{
                    objectFit: 'contain',
                  }}
                />
                <Form
                    name="sign_up"
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark={false}
                    style={{ marginTop: '20px' }}
                >
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                    >
                        <Input size="large" />
                    </Form.Item>

                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please input your name!' }]}
                    >
                        <Input size="large" />
                    </Form.Item>

                    <Form.Item
                        name="organization"
                        label="Organization"
                        rules={[{ required: true, message: 'Please input your organization!' }]}
                    >
                        <Input size="large" />
                    </Form.Item>

                    <Form.Item
                        name="role"
                        label="Role"
                        rules={[{ required: true, message: 'Please select your role!' }]}
                    >
                        <Select placeholder="Select your role" size="large">
                            <Option value="sonographer">Sonographer</Option>
                            <Option value="medical_student">Medical Student</Option>
                            <Option value="patient">Patient</Option>
                            <Option value="other">Other</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password size="large" />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password size="large" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" size="large" htmlType="submit" block>
                            Sign Up
                        </Button>
                    </Form.Item>

                    <div style={{ marginTop: '10px' }}>
                        Already have an account? <Link to="/signin">Sign In</Link>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default SignUp;
